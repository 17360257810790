import axios from "axios";

// const BASE_URL = "http://localhost:8080/api_umohe_com/rest/v1";
const BASE_URL = "https://api.umohe.com/rest/v1";

axios.interceptors.request.use(
    function (config) {
        config.headers.common["Accept"] = "application/json";
        config.headers.common["Content-Type"] = "application/json";
        if (localStorage.getItem("token")) {
            config.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("token");
            console.log(localStorage.getItem("token"));
        }
        return config;
    },
    function (error) {
        console.log("error: ", error);
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    // test the async
    async function (response) {
        // console.log(response.data);
        return Promise.resolve(response);
    },
    function (error) {
        // console.log(error);
        if (error.response && error.response.status === 401) {
            window.location.href = "/";
        }
        return Promise.reject(error);
    }
);

export default {
    baseUrl: BASE_URL,
    accounts: {
        entry: {
            login(data) {
                return axios.post(`${BASE_URL}/accounts/entry`, data);
            },
            forgot(data) {
                return axios.post(`${BASE_URL}/accounts/entry/forgot`, data);
            },
            changePassword(data) {
                return axios.post(`${BASE_URL}/accounts/entry/change_password`, data);
            },
            checkUsername(data) {
                return axios.get(`${BASE_URL}/accounts/username/${data}`);
            },
        },
        mySubs: {
            list() {
                return axios.get(`${BASE_URL}/accounts/mysubs`);
            },
            find(id) {
                return axios.get(`${BASE_URL}/accounts/mysubs/${id}`);
            },
            update(id, data) {
                return axios.put(`${BASE_URL}/accounts/mysubs/${id}`, data);
            },
            findBySubscriberTypeLoc(data) {
                return axios.post(`${BASE_URL}/accounts/mysubs/subtype_location`, data);
            },
            subscriberUsers() {
                return axios.get(`${BASE_URL}/accounts/mysubs/subscriber`);
            },
        },
        profilia(data) {
            return axios.get(`${BASE_URL}/accounts/profile/${data}`);
        },
        updatePassword(data) {
            return axios.put(`${BASE_URL}/accounts/changepass`, data);
        },
        find() {
            return axios.get(`${BASE_URL}/accounts`);
        },
        myPicture(data) {
            return axios.put(`${BASE_URL}/accounts/pix`, data);
        },
        updateProfile(data) {
            return axios.put(`${BASE_URL}/accounts`, data);
        },
        add(data) {
            return axios.post(`${BASE_URL}/accounts/add`, data);
        },
        active(id, data) {
            return axios.put(`${BASE_URL}/accounts/status/${id}`, data);
        },
        reviews: {
            create(data) {
                return axios.post(`${BASE_URL}/accounts/reviews`, data);
            },
            list() {
                return axios.get(`${BASE_URL}/accounts/reviews`);
            },
            on(id) {
                return axios.get(`${BASE_URL}/accounts/reviews/on/${id}`);
            },
            find(id) {
                return axios.get(`${BASE_URL}/accounts/reviews/${id}`);
            },
            update(id, data) {
                return axios.put(`${BASE_URL}/accounts/reviews/${id}`, data);
            },
            remove(id) {
                return axios.delete(`${BASE_URL}/accounts/reviews/${id}`);
            },
        },
    },
    general: {
        types: {
            dynamic() {
                return axios.get(`${BASE_URL}/general/types/dynamic`);
            },
            active(active) {
                console.log(active);
                return axios.get(`${BASE_URL}/general/types/active/${active}`);
            },
            byName(name) {
                return axios.get(`${BASE_URL}/general/types/name/${name}`);
            },
            search() {
                return axios.get(`${BASE_URL}/general/types/search`);
            },
        },
        country: {
            list() {
                return axios.get(`${BASE_URL}/general/country`);
            },
        },
        regions: {
            byCountry(id) {
                return axios.get(`${BASE_URL}/general/regions/country/${id}`);
            },
        },
        district: {
            byRegion(id) {
                return axios.get(`${BASE_URL}/general/districts/regions/${id}`);
            },
        },
        gender: {
            list() {
                return axios.get(`${BASE_URL}/general/gender`);
            },
        },
        salutations: {
            list() {
                return axios.get(`${BASE_URL}/general/salutations`);
            },
        },
        relationTo: {
            list() {
                return axios.get(`${BASE_URL}/general/relationto`);
            },
        },
        marriage: {
            list() {
                return axios.get(`${BASE_URL}/general/marital_status`);
            },
        },
        specialty: {
            list() {
                return axios.get(`${BASE_URL}/general/specialty`);
            },
            active(bool) {
                return axios.get(`${BASE_URL}/general/specialty/active/${bool}`);
            },
            find(id) {
                return axios.get(`${BASE_URL}/general/specialty/${id}`);
            },
            search() {
                return axios.get(`${BASE_URL}/general/specialty/search`);
            },
        },
        specialtyDetails: {
            list(id) {
                return axios.get(`${BASE_URL}/general/specialdetail/specialty/${id}`);
            },
        },
        docType: {
            list() {
                return axios.get(`${BASE_URL}/general/doctypes`);
            },
        },
        infoTypes: {
            list() {
                return axios.get(`${BASE_URL}/general/infotypes`);
            },
        },
        meetypes: {
            list() {
                return axios.get(`${BASE_URL}/general/meetypes`);
            },
        },
        systemics: {
            list() {
                return axios.get(`${BASE_URL}/general/systemics`);
            },
        },
        systemicsDetail: {
            list(id) {
                return axios.get(
                    `${BASE_URL}/general/systemics/details/category/${id}`
                );
            },
        },
        measurements: {
            byMeasurementCategory(id) {
                return axios.get(`${BASE_URL}/general/measurements/by/category/${id}`);
            },
        },
        roles: {
            list() {
                return axios.get(`${BASE_URL}/general/roles/manager`);
            },
        },
        medicat: {
            list() {
                return axios.get(`${BASE_URL}/general/medicat`);
            },
        },
        pharmacat: {
            list() {
                return axios.get(`${BASE_URL}/general/pharmacat`);
            },
            shortlist() {
                return axios.get(`${BASE_URL}/general/pharmacat/short`);
            },
            find(id) {
                return axios.get(`${BASE_URL}/general/pharmacat/${id}`);
            },
        },
        profession: {
            list() {
                return axios.get(`${BASE_URL}/general/profession`);
            },
            country(id) {
                return axios.get(`${BASE_URL}/general/profession/country/${id}`);
            },
        },
    },
    home: {
        search: {
            search(data) {
                return axios.post(`${BASE_URL}/home/search/text`, data);
            },
            category(data) {
                return axios.post(`${BASE_URL}/home/search/protype`, data);
            },
            specialty(data) {
                return axios.post(`${BASE_URL}/home/search/specialty`, data);
            },
            specialtype(data) {
                return axios.post(`${BASE_URL}/home/search/specialtype`, data);
            },
            subtype(data) {
                return axios.post(`${BASE_URL}/home/search/subtype`, data);
            },
            catLocation(data) {
                return axios.post(`${BASE_URL}/home/search/catlocation`, data);
            },
            name(data) {
                return axios.post(`${BASE_URL}/home/search/name`, data);
            },
            location(data) {
                return axios.post(`${BASE_URL}/home/search/location`, data);
            },
            searchSpecialty(data) {
                return axios.post(`${BASE_URL}/home/search/specialty`, data);
            },
        },
    },
    subscribers: {
        add(data) {
            return axios.post(`${BASE_URL}/accounts/subscribers`, data);
        },
        patients(data) {
            return axios.post(`${BASE_URL}/accounts/subscribers/patients`, data);
        },
        detail() {
            return axios.get(`${BASE_URL}/accounts/subscribers`);
        },
        profile(data) {
            return axios.put(`${BASE_URL}/accounts/subscribers/profile`, data);
        },
        update(data) {
            return axios.put(`${BASE_URL}/accounts/subscribers`, data);
        },
        subsidiary: {
            subscribers(data) {
                return axios.post(
                    `${BASE_URL}/accounts/subscribers/subsidiary/subscriber`,
                    data
                );
            },
        },
        clients(data) {
            return axios.post(`${BASE_URL}/accounts/subscribers/clients`, data);
        },
    },
    mySubscriptions: {
        profiles(data) {
            return axios.post(`${BASE_URL}/accounts/mysubs/profiles`, data);
        },
    },
    chat: {
        question(data) {
            return axios.post(`${BASE_URL}/chat/gpt/question`, data);
        },
    },
    cms: {
        async page(data) {
            return await axios.post(`${BASE_URL}/cms/pages/webpage`, data);
        },
    },
    testimonials: {
        testimonies() {
            return axios.get(`${BASE_URL}/testimonials`);
        },
        testimonials(data) {
            return axios.post(`${BASE_URL}/testimonials/subscriber`, data);
        },
    },
    schedule: {
        premiums(data) {
            return axios.post(`${BASE_URL}/medics/available/monthly/people`, data);
        },
        // time(data) {
        //   return axios.post(`${BASE_URL}/medics/available/time`, data);
        // },
        day(data) {
            return axios.post(`${BASE_URL}/medics/available/selected`, data);
        },
        month(data) {
            return axios.post(`${BASE_URL}/medics/available/monthly`, data);
        },
        monthUser(data) {
            return axios.post(`${BASE_URL}/medics/available/monthly/users`, data);
        },
        subscriber(data) {
            return axios.post(`${BASE_URL}/medics/available/sub_active`, data);
        },
        // subUser(data) {
        //   return axios.post(`${BASE_URL}/medics/available/sub_active_user`, data);
        // },
        // schedules(data) {
        //   return axios.post(`${BASE_URL}/medics/available/schedules`, data);
        // },
        subscriberDays(data) {
            console.log("data:", data);
            return axios.post(`${BASE_URL}/medics/available/date`, data);
        },
        scheduleDate(date) {
            return axios.get(`${BASE_URL}/medics/available/date/${date}`);
        },
    },
    medic: {
        patient: {
            request: {
                create(data) {
                    return axios.post(`${BASE_URL}/medics/patient/request`, data);
                },
            },
        },
    },
    blogs: {
        list(data) {
            return axios.get(`${BASE_URL}/blogs/published/${data}`);
        },
        find(id) {
            return axios.get(`${BASE_URL}/blogs/published/detail/${id}`);
        },
    },
    support: {
        sendmail(data) {
            return axios.post(`${BASE_URL}/support`, data);
        },
    },
};
