<template>
  <div id="app" v-scroll="onScroll">
    <v-app>
      <div v-if="window.width <= 670">
        <NavSmall />
      </div>
      <div v-else>
        <NavBig />
        <!-- :myId="myId" :myCart="myCart" -->
      </div>

      <v-main>
        <transition mode="out-in" name="fade">
          <router-view></router-view>
        </transition>
      </v-main>

      <NavFooter />

      <v-fab-transition>
        <v-btn
          @click="myScroll"
          style="position: fixed; margin-bottom: 50px"
          v-show="!hidden"
          fab
          x-large
          dark
          absolute
          color="blue"
          bottom
          right
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-app>
  </div>
</template>

<script>
import Utils from "@/mixins/utils.js";
import NavSmall from "@/components/nav/nav-small.vue";
import NavBig from "@/components/nav/nav-big.vue";
import NavFooter from "@/components/nav/nav-footer.vue";

export default {
  metaInfo: {
    titleTemplate: "%s - Umohe Healthcare",
  },
  mixins: [Utils],
  components: {
    NavSmall,
    NavBig,
    NavFooter,
  },
  data: () => ({
    myId: null,
    myCart: null,
  }),
  computed: {
    hidden: function () {
      if (this.windowTop > 150) {
        return false;
      } else {
        return true;
      }
    },
    absolute: function () {
      if (this.windowTop > 50) {
        return false;
      } else {
        return true;
      }
    },
    topColor: function () {
      if (this.windowTop > 50) {
        return "rgba(0,0,0,.3)";
      } else {
        return "transparent";
      }
    },
    appFlat: function () {
      if (this.windowTop > 50) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {},
  watch: {},
  methods: {
    myScroll() {
      // console.log(e.target.documentElement.scrollTop);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
