<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      fixed
      temporary
    >
      <v-toolbar flat>
        <v-toolbar-title>
          <v-img
            :src="require('../../assets/images/logo.webp')"
            width="120"
          ></v-img>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" icon @click.stop="mini = !mini">
          <v-icon>{{ mini ? "mdi-chevron-right" : "mdi-chevron-left" }}</v-icon>
        </v-btn> -->
      </v-toolbar>

      <v-divider></v-divider>
      <br />
      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.link"
          ripple
          router
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="capitalize">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item href="/">
          <v-list-item-icon>
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="capitalize">
              Account Login
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item
          v-for="item in others"
          :key="item.title"
          :to="item.link"
          ripple
          router
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="capitalize">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :color="topColor" :flat="appFlat" fixed>
      <v-toolbar-title>
        <v-img
          :src="
            this.windowTop > 50
              ? require('@/assets/images/logo_light.webp')
              : require('@/assets/images/logo.webp')
          "
          max-width="110"
        ></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon
        :dark="menudark"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-app-bar>
  </div>
</template>

<script>
import Utils from "@/mixins/utils.js";

export default {
  mixins: [Utils],
  data: () => ({
    mini: false,
    drawer: false,
    scrollPosition: null,
    barColor: "transparent",
    logoLight: "require('@/assets/images/logo_light.png')",
    logoDark: "require('@/assets/images/logo.png')",
    items: [
      { title: "Home", icon: "fa fa-home", link: { name: "home" } },
      {
        title: " How it Works",
        icon: "mdi-arrow-right",
        link: { name: "our_works" },
      },
      {
        title: "Appointments",
        icon: "mdi-arrow-right",
        link: { name: "appointments" },
      },
      {
        title: "register",
        icon: "mdi-arrow-right",
        link: { name: "register" },
      },
    ],
    others: [
      {
        title: "client",
        icon: "mdi-arrow-right",
        link: { name: "client" },
      },
      {
        title: "medics",
        icon: "mdi-arrow-right",
        link: { name: "medics" },
      },
      {
        title: "pharmacy",
        icon: "mdi-arrow-right",
        link: { name: "pharmacy" },
      },
      {
        title: "laboratory",
        icon: "mdi-arrow-right",
        link: { name: "lab" },
      },
      {
        title: "about us",
        icon: "mdi-arrow-right",
        link: { name: "about" },
      },
      {
        title: "Terms of Service",
        icon: "mdi-arrow-right",
        link: { name: "tos" },
      },
      {
        title: "contact us",
        icon: "mdi-arrow-right",
        link: { name: "contact" },
      },
    ],
  }),
  computed: {
    absolute: function () {
      return this.windowTop <= 50;
    },
    topColor: function () {
      if (this.windowTop > 50) {
        return "rgba(0,0,0,.8)";
      } else {
        return "transparent";
      }
    },
    appFlat: function () {
      return this.windowTop <= 50;
    },
    menudark: function () {
      return this.windowTop > 50;
    },
  },
  watch: {},
  methods: {},
};
</script>
