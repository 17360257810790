import Restful from "@/services/RestFul";

export default {
  data: () => ({
    scrollPosition: null,
    window: {
      width: 0,
      height: 0,
    },
    windowTop: 0,
  }),
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  created() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {},
  methods: {
    async lookupCart() {
      let self = this;
      await Restful.cart
        .client()
        .then((response) => {
          console.log(response.data);
          let size = response.data.size;
          console.log("size", size);
          this.$store.dispatch("addToCart", size === undefined ? 0 : size);
          this.$store.dispatch("activateClient", true);
        })
        .catch((error) => {
          console.log(error.response);
          self.loadevent = false;
        });
    },
    truncate(str, num) {
      // console.log(str + " == " + num);
      if (str === null) {
        return "...";
      } else {
        if (str.length <= num) {
          return str;
        } else {
          return str.slice(0, num) + "...";
        }
      }
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    onScroll(e) {
      this.windowTop = e.target.documentElement.scrollTop;
      //   console.log({ top: this.windowTop });
    },
    _add(text) {
      let newText = text.replace(" ", "-");
      return newText;
    },
    _remove(text) {
      let newText = text.replace("-", " ");
      return newText;
    },
    _encode(data) {
      return btoa(data);
    },
    _decode(data) {
      return atob(data);
    },
    uriEncode(a) {
      if (a === null) {
        return null;
      } else {
        let text = a.replaceAll(" ", "-");
        let en = encodeURI(text);
        return en;
      }
    },
    uriDecode(a) {
      if (a === null) {
        return null;
      } else {
        let text = a.replaceAll("-", " ");
        let en = decodeURI(text);
        return en;
      }
    },
    currency(num) {
      console.log(num);
      let curr = new Intl.NumberFormat("en-GH", {
        style: "currency",
        currency: "GHC",
      }).format(num);
      return curr;
    },
  },
};
