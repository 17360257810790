import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const state = {
  activeClient: false,
  cart: [],
};
const mutations = {
  ACTIVATE_USER(state, payload) {
    state.activeClient = payload;
  },
  REMOVE_ACTIVE_CLIENT(state, payload) {
    const i = state.cart.map((item) => item.id).indexOf(payload.id);
    state.cart.splice(i, 1);

    // state.activeClient = payload;
  },
  ADD_TO_CART(state, payload) {
    state.cart.push(payload);
  },
};
const actions = {
  activateClient(state, payload) {
    state.commit("ACTIVATE_USER", payload);
  },
  removeFromCart(state, payload) {
    state.commit("REMOVE_ACTIVE_CLIENT", payload);
  },
  addToCart(state, payload) {
    state.commit("ADD_TO_CART", payload);
  },
};
const getters = {
  activeClient: (state) => state.activeClient,
  cart: (state) => state.cart,
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
});
