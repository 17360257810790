<template>
  <v-footer>
    <v-container>
      <v-row>
        <v-col cols="12" sm="8">
          <div class="footerlinks">
            <!-- <v-card outlined v-for="n in 50" :key="n">card {{ n }} / 50</v-card> -->
            <div class="links">
              <div class="mytitle">Pages</div>
              <router-link :to="{ name: 'home' }">home</router-link>
              <router-link :to="{ name: 'client' }"> clients </router-link>
              <router-link :to="{ name: 'medics' }"> medics </router-link>
              <router-link :to="{ name: 'lab' }"> laboratory</router-link>
              <router-link :to="{ name: 'pharmacy' }"> pharmacy</router-link>
            </div>

            <div class="links">
              <div class="mytitle">services</div>
              <router-link :to="{ name: 'our_works' }">
                how it works
              </router-link>
              <router-link :to="{ name: 'searching' }">search</router-link>
            </div>

            <div class="links">
              <div class="mytitle">information</div>
              <router-link :to="{ name: 'about' }" :class="{ disabled: false }">
                about us
              </router-link>
              <!-- <router-link :to="{ name: 'team' }" :class="{ disabled: false }">
                our team
              </router-link> -->
              <router-link
                :to="{ name: 'blog.start' }"
                :class="{ disabled: false }"
              >
                blogs
              </router-link>
            </div>

            <div class="links">
              <div class="mytitle">legal</div>
              <router-link
                :disabled="false"
                :to="{ name: 'privacy' }"
                :class="{ disabled: false }"
              >
                privacy
              </router-link>
              <router-link
                :disabled="true"
                :to="{ name: 'practice' }"
                :class="{ disabled: false }"
              >
                practice
              </router-link>
              <router-link :to="{ name: 'tos' }" :class="{ disabled: false }">
                terms of service
              </router-link>
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="footercons">
            <div class="links">
              <div class="mytitle">talk to us</div>
              <router-link :to="{ name: 'contact' }">contact us</router-link>
              <div>
                <a
                  :href="`tel:${cms('contact').data}`"
                  v-text="cms('contact').data"
                ></a
                >,
                <a
                  :href="`tel:${cms('contact1').data}`"
                  v-text="cms('contact1').data"
                ></a>
              </div>
            </div>
          </div>

          <v-row class="social" dense>
            <v-col cols="12" lg="3" md="6" sm="6">
              <v-btn
                color="teal"
                data-action="share/whatsapp/share"
                :href="cms('whatsapp').data"
                target="_blank"
                text
                x-small
              >
                whatsapp
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6">
              <v-btn
                color="teal"
                text
                x-small
                :href="cms('facebook').data"
                target="_blank"
              >
                facebook
                <v-icon color="blue">mdi-facebook</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6">
              <v-btn
                color="teal"
                text
                x-small
                :href="cms('twitter').data"
                target="_blank"
              >
                twitter
                <v-icon color="blue">mdi-twitter</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6">
              <v-btn
                color="teal"
                text
                x-small
                :href="cms('linkedin').data"
                target="_blank"
              >
                linkedin
                <v-icon color="blue">mdi-linkedin</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="copyright">
        <v-divider></v-divider>
        &copy; 2022 UMOHE , All Rights Reserved
      </div>
    </v-container>
  </v-footer>
</template>

<script>
import Restful from "@/services/RestFul";

export default {
  data: () => ({
    disabled: true,
    webcontent: [],
  }),
  created() {
    this.webpage();
  },
  methods: {
    links(linkName) {
      if (this.disabled === true) {
        return "";
      } else {
        this.$router.push({ name: linkName });
      }
    },
    webpage() {
      let name = "footing";
      let data = { name };
      Restful.cms
        .page(data)
        .then((response) => {
          if (response.status === 200) {
            console.log("response:", response);
            // this.myMeta.title = response.data.title;
            // this.myMeta.description = response.data.description;
            // this.myMeta.keywords = response.data.keywords;
            this.webcontent = response.data.contents;
            // console.log("footing:", this.webcontent);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cms(search) {
      var data_filter = this.webcontent.filter(
        (element) => element.id == search
      );
      return data_filter[0];
    },
  },
};
</script>
