<template>
  <v-dialog v-model="mydialog" max-width="500" persistent>
    <v-card>
      <v-form @submit.prevent="submit">
        <v-toolbar flat>
          <v-toolbar-title>Account Login</v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            dense
            label="Email or Mobile Number"
            v-model="login.email"
          ></v-text-field>
          <v-text-field
            dense
            label="Password"
            type="password"
            v-model="login.password"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" @click="register"> register now </v-btn>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn small text color="red" v-on="on" @click="closeDialog">
                NO
              </v-btn>
            </template>
            <span>cancel</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                text
                color="blue"
                v-on="on"
                type="submit"
                :disabled="$v.login.$invalid"
              >
                yes
              </v-btn>
            </template>
            <span>Submit</span>
          </v-tooltip>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Utils from "../../mixins/utils";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [Utils, validationMixin],
  props: {
    loginAgainDialog: Boolean,
    myId: { type: Number, default: 0 },
  },
  computed: {
    mydialog() {
      return this.loginAgainDialog;
    },
  },
  data() {
    return {
      login: {
        email: null, //"patience@umohe.com",
        password: null, //"patience4us!",
      },
      dialog: false,
    };
  },
  validations: {
    login: {
      email: { required },
      password: { required },
    },
  },
  created() {},
  methods: {
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeLogin", payload);
    },
    submit() {
      let email = this.login.email;
      let password = this.login.password;
      let d = { email, password };
      let payload = { id: this.myId, state: false, data: d };
      this.$emit("submitLogin", payload);
    },
    register() {
      if (this.$route.name !== "register") {
        this.$router.push({
          name: "register",
        });
      }
      let payload = { id: this.myId, state: false };
      this.$emit("closeRegister", payload);
    },
  },
};
</script>
