<template>
  <div>
    <v-app-bar
        :color="topColor"
        :flat="appFlat"
        :style="{ padding: stylePadding, background: topColor }"
        dark
        fixed
        height="80"
    >
      <!--
            style="padding: 0px 0px 25px;
            background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0.8) 50%,rgba(255,255,255,0.8) 55%,rgba(255,255,255, 0) 100%);
            "
            -->

      <v-container>
        <v-toolbar color="transparent" flat>
          <v-toolbar-title>
            <router-link :to="{ name: 'home' }">
              <v-img
                  :src="
                  this.windowTop > 50
                    ? require('@/assets/images/logo_light.webp')
                    : require('@/assets/images/logo.webp')
                "
                  width="170"
              ></v-img>
            </router-link>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <nav>
            <div :class="menuLink">
              <router-link :to="{ name: 'home' }">Home</router-link>
              <router-link :to="{ name: 'our_works' }">
                How it Works
              </router-link>
              <router-link :to="{ name: 'appointments' }" class="reg">
                Appointments
              </router-link>
              <router-link :to="{ name: 'register' }" class="reg">
                Register
              </router-link>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn :color="colorLink"
                         dark
                         fab outlined
                         rounded
                         small
                         style="margin-left: 20px"
                         target="_blank"
                         @click="access"
                         v-on="on"
                  >
                    <v-icon> fa-user</v-icon>
                  </v-btn>
                </template>
                <span>Customer Portal</span>
              </v-tooltip>

              <v-badge
                  :class="{ badgeDark: badgeColor, badgeLight: !badgeColor }"
                  :color="colorLink"
                  :content="cartStatus"
                  :dark="badgeColor"
                  :value="cartStatus"
                  avatar
                  overlap
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                        :color="colorLink"
                        dark
                        fab
                        outlined
                        rounded
                        small
                        style="margin-left: 20px"
                        v-on="on"
                        @click.stop="userCheck"
                    >
                      <v-icon>
                        {{
                          accountStatus
                              ? "mdi-account-check-outline"
                              : "mdi-account-cancel-outline"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ accountStatus ? "Go to My cart" : "Account Login" }}</span>
                </v-tooltip>
              </v-badge>
              <LoginAgain
                  :loginAgainDialog="loginAgainDialog"
                  @closeLogin="closeLogin"
                  @closeRegister="closeRegister"
                  @submitLogin="submitLogin"
              />

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-show="showLogout" color="error" fab icon small style="margin-left: 10px" v-on="on"
                         @click.stop="logout">
                    <v-icon>fa-sign-out</v-icon>
                  </v-btn>
                </template>
                <span>Logout</span>
              </v-tooltip>
            </div>
          </nav>
        </v-toolbar>
      </v-container>
    </v-app-bar>
    <v-snackbar
        v-model="snack.bar"
        :color="snack.color"
        :multi-line="snack.multiLine"
        :timeout="snack.timeout"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils.js";
import LoginAgain from "../dialogs/login-again.vue";

export default {
  mixins: [Utils],
  components: {LoginAgain},
  data: () => ({
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      timeout: -1,
      text: "Processing ...",
    },
    stylePadding: "padding: 0px 0px 25px",
    styleBackground: "linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0.8) 50%,rgba(255,255,255,0.8) 55%,rgba(255,255,255, 0) 100%)",
    userStatus: null,
    loginAgainDialog: false,
    accountStatus: false,
    appointments: [],
    showLogout: false,
  }),
  computed: {
    cartStatus() {
      let cart = this.$store.getters.cart;
      return cart.length;
    },
    // iconValue() {
    //   let cart = this.$store.getters.cart;
    //   if (cart > 0) {
    //     return "mdi-cart-outline";
    //   } else {
    //     return "";
    //   }
    // },
    // cartValue() {
    //   // let active = this.$store.getters.activeClient;
    //   let cart = this.$store.getters.cart;
    //   if (cart > 0) {
    //     return true
    //   } else {
    //     return false;
    //   }
    // },
    absolute: function () {
      if (this.windowTop > 50) {
        return false;
      } else {
        return true;
      }
    },
    topColor: function () {
      if (this.windowTop > 50) {
        return "rgba(12, 7, 3, 0.85)";
      } else {
        return "linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0.8) 50%,rgba(255,255,255,0.8) 55%,rgba(255,255,255, 0) 100%)";
      }
    },
    appFlat: function () {
      if (this.windowTop > 50) {
        return false;
      } else {
        return true;
      }
    },
    // menudark: function () {
    //   if (this.windowTop > 50) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    badgeColor() {
      if (this.windowTop > 50) {
        return true;
      } else {
        return false;
      }
    },
    colorLink() {
      if (this.windowTop > 50) {
        return "yellow lighten-4";
      } else {
        return "light-blue darken-3";
      }
    },
    menuLink() {
      if (this.windowTop > 50) {
        return "dark";
      } else {
        return "light";
      }
    },
  },
  watch: {},
  created() {
    this.userStatus = localStorage.getItem("token");
    this.accountStatus = this.userStatus !== null ? true : false;
    this.showLogout = this.userStatus !== null ? true : false;
  },
  methods: {
    access() {
      window.open("https://www.my.umohe.com", "_blank");
    },
    userCheck() {
      // this.$store.getters.activeClient;
      let active = localStorage.getItem("token");
      console.log('active', active)
      if (active !== null) {
        // let cart = this.$store.getters.cart;
        // console.log(cart)
        this.$router.push({name: "cart.items"}).catch(() => {
          console.log("Already opened");
        });
      } else {
        this.loginAgainDialog = true;
      }
    },
    closeLogin(p) {
      this.loginAgainDialog = p.state;
    },
    closeRegister(p) {
      this.loginAgainDialog = p.state;
    },
    submitLogin(p) {
      this.loginAgainDialog = p.state;
      console.log("data", p.data);
      this.login(p.data);
    },
    login(data) {
      let self = this;
      Restful.accounts.entry
          .login(data)
          .then((response) => {
            console.log(response.data);
            if (response.status === 200) {
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("firstName", response.data.first_name);
              localStorage.setItem("lastName", response.data.last_name);
              localStorage.setItem("pix", response.data.pix);
              this.accountStatus = true;
              this.showLogout = true;
            } else {
              this.snack.text = "Processing ...";
              this.snack.bar = true;
            }
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.bar = true;
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
    },
    logout() {
      console.log("logout");
      this.$store.dispatch("activateClient", false);
      localStorage.clear();
      setTimeout(() => {
        this.$router.push({name: "home"});
        this.accountStatus = false;
        this.showLogout = false;
      }, 500);
    },
    inside() {
      //
      let active = this.$store.getters.activeClient;
      console.log("active", active);
      if (active === true) {
        let me = this.getCart();
        let see = me[0].client;
        if (see.id > 0) {
          this.loggedIn = "Hello, " + see.first;
          this.loginEnabled = true;
          this.bgColor = "rgb(237, 237, 237)";
          this.textColor = "rgb(1, 99, 1)";
        } else {
          this.loggedIn = "Check your Appointments";
          this.loginEnabled = false;
          this.bgColor = "black";
          this.textColor = "white";
        }
        console.log(see);
        console.log(see.first);
      } else {
        this.loggedIn = "Check your Appointments";
        this.loginEnabled = false;
        this.bgColor = "black";
        this.textColor = "white";
      }
    },
    pix(p) {
      console.log(p)
    }
  },
};
</script>
